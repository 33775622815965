.ant-select {
  width: 100%;
  min-width: 80px;
  border-radius: 5px;

  &:focus, &:hover {
    border: none;
    border-color: @info-color !important;
    box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);
  }
}

.ant-select-dropdown {
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(174, 180, 194, 0.35);
}

@media screen and (max-width: 678px) {
  .ant-select-fullscreen {
    position: fixed;
    top: 0;
    left: 0 !important;
    right: 0;
    bottom: 0;
  }
}

.ant-select-selection-overflow {
  .ant-select-selector {
    color: @primary-color;
    border: 1px solid #c5ccdb63 !important;
  }
}

.ant-select-arrow {
  svg {
    height: 10px;
    color: @normal-color;
  }
}

.ant-select-selector {
  &:focus,
  &:active {
    border: none;
    box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);
  }
}

.ant-select-open .ant-select-selector {
  box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);
}

.ant-select-item {
  padding: 6px !important;
  min-height: 30px !important;
  margin: 0;
  &:not(.ant-select-item-option-disabled) {
    border-radius: 0;
    &:hover,
    &:focus {
      color: @primary-color;
      background: #f8f9fc;
    }
  }
  &.ant-select-item-option-active, &.ant-select-item-option-selected {
    color: @primary-color;
    background: darken(#f8f9fc, 5%) !important;
    &:hover {
      background: #f8f9fc !important;
    }
  }
}

.ant-select-item-group-title {
  margin: 0 12px 0 20px;
  padding: 0;
  font-size: 18px;
  color: @primary-color;
  font-weight: 500;
  border-bottom: 1px solid rgba(173, 182, 204, 0.35);
}

.phone-input-prefix {
  min-width: 60px;
  transition: all 0.3s;
  .ant-select-selection-item {
    [data-display-country] {
      display: none;
    }
  }
}

.ant-select-loading .ant-select-selection-item {
  display: none;
}
