

.staff-menu__list {
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: @primary-color !important;
  }

  .anticon {
    color: @primary-color;
  }

  .ant-list-item {
    padding: 10px;
    &:hover {
      background: #f0f1f5;
    }
  }
  .ant-list-item-meta-avatar {
    width: 23px;
    min-height: 60px;
    font-size: 25px;
    display: flex;
    align-items: center;
  }
}
