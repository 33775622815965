@info-color: #6ce1f7;
@normal-color: #14316D;
@primary-color: #14316D;
@btn-default-border: #adb6cc;
@error-color: #dc3545;
@link-color: #4395D4;

@import './ant-theme/overrides/index.less';
@import './ant-theme/helpers/index.less';


body {
  padding: 0 !important;
  margin: 0 !important;
}

* {
  font-family: Work Sans, sans-serif;
}

//.stop-scroll {
//  overflow: hidden;
//}
//
//.selected-row {
//  background-color: #c8c8c8 !important;
//
//  &:hover, &:focus {
//    background-color: #c8c8c8 !important;
//  }
//}
//
//::-webkit-scrollbar {
//  width: 8px;
//}
//
//::-webkit-scrollbar-corner {
//  width: 8px;
//}
//
//::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 5px grey;
//  border-radius: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: #14316D;
//  border-radius: 10px;
//}
@font-family: 'Work Sans', sans-serif;@border-radius-base: 20px;@primary-color: #14316D;@info-color: #6ce1f7;@minor-color: #ADB6CC;@link-color: #4395D4;@link-hover-decoration: underline;@label-color: #14316D;@normal-color: #13316D;@font-size-base: 16px;@font-size-lg: 18px;@font-size-xlg: 28px;@font-size-sm: 14px;@border-color-base: #ADB6CC;@form-item-margin-bottom: 19px;@disabled-bg: #F8F9FC;@layout-body-background: #F8F9FC;@avatar-color: #14316D;@avatar-bg: #E9ECF3;@box-border-decoration: 1px solid #e9e9e9;@btn-font-weight: 400;@btn-height-base: 42px;@btn-height-lg: 50px;@btn-height-sm: 34px;@btn-default-color: white;@btn-default-bg: #ADB6CC;@btn-default-border: #98a1b5;@input-height-base: 42px;@input-height-lg: 50px;@input-height-sm: 34px;@input-hover-border-color: #13316D;@table-header-bg: white;@table-footer-bg: white;@table-header-color: #14316D;@table-row-hover-bg: #F8F9FC;@z-index-mobile-menu: 1;@z-index-mobile-menu-header: 2;@z-index-mobile-sidebar: 1;@time-cell-min-width: 120;@time-cell-week-min-width: 40;@time-cell-height: 100;@disabled-time-bg: repeating-linear-gradient(-55deg, #eff2f8, #eff2f8 10px, #f8f9fc 10px, #f8f9fc 20px);@calendar-user-header-height: 50;@prefix-time-height: 40;@time-column-width: 80;@time-column-width-mobile: 40;@calendar-top-offset: 161;@calendar-mobile-top-offset: 110;@calendar-time-cell-width: 60;@calendar-time-cell-mobile-width: 45;