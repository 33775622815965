button.ant-btn {
  font-weight: 400;
  white-space: nowrap;
  border: none;
  text-align: center;
  color: white;
  background: @btn-default-border;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  cursor: pointer;
}


button.ant-btn-background-ghost {
  background: white !important;
  border: none;
  color: @normal-color !important;

}

button.ant-btn.bold {
  font-weight: 600;
}

button.ant-btn.semi-bold {
  font-weight: 500;
}

button.ant-btn.upper-case {
  text-transform: uppercase !important;
}

.full-width-btn {
  width: 100%;
}

button.ant-btn-minor,
button.ant-btn-primary,
.filter-datepicker-container button.ant-btn-minor.active {
  color: white;
  background: @primary-color;
  border-color: @primary-color;
  &:hover,
  &:focus,
  &:active {
    background: darken(@primary-color, 10%);
    color: white;
    border-color: transparent;
  }
}

button.ant-btn-support {
  color: #61749d;
  background: #e9ecf3;
  border-color: #e9ecf3;
  &:hover,
  &:focus,
  &:active {
    background: @primary-color;
    color: white;
    border-color: transparent;
  }
}

button.ant-btn-white {
  color: @primary-color;
  background: white;
  box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16);
  border-color: rgba(19, 49, 109, 0.02);
  &:hover,
  &:focus,
  &:active {
    background: @btn-default-border;
    color: white;
    border-color: transparent;
  }
}

button.ant-btn-light {
  color: #fff;
  background: #2472e8;
  padding: 10px 38px 10px 32px;
  height: unset;
  &:hover,
  &:focus,
  &:active {
    background: darken(@primary-color, 10%);
    border-color: transparent;
  }
}

button.ant-btn-outline {
  color: @primary-color;
  background: #fff;
  border: 1px solid #adb6cc;
  height: unset;

  &:hover,
  &:focus,
  &:active {
    background: @btn-default-border;
    color: white;
    border-color: transparent;
  }
}

button.ant-btn-dangerous {
  color: white !important;
  background: #ff5e5e !important;
  font-weight: 600;
  &:hover,
  &:focus,
  &:active {
    background: darken(#ff5e5e, 10%);
  }
}

button.ant-btn-danger-text {
  color: #ff5e5e;
  background: white;
  border-color: transparent;
  box-shadow: none;
  font-weight: 600;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    background: darken(#ff5e5e, 10%);
    text-decoration-color: @error-color;
  }
}

button.ant-btn-background-ghost {
  color: @primary-color;
  background: white !important;

  &:hover,
  &:focus,
  &:active {
    background: #e9ecf3 !important;
    color: #61749d;
    border-color: transparent;
  }

  &.active {
    background: @info-color !important;
    color: white;
    border: none;
  }
}

button.ant-btn-link {
  color: @primary-color;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline !important;
  }
}

button.ant-btn-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button.ant-btn-radius {
  border-radius: 5px;
}
