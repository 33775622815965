

.date-picker-form {
  .ant-input,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
}

.date-picker-right-position {
  .react-datepicker-popper {
    margin-left: -215px;
    @media screen and (max-width: 768px) {
      margin-left: -72px;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__navigation {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px !important;
  width: 38px !important;
  border: none !important;
  border-radius: 50% !important;
  text-indent: 0 !important;
  font-size: 12px;

  svg {
    color: #67768c;
  }

  &:hover {
    color: #67768c;
    background: #e9ecf3 !important;
  }

  &:focus {
    outline: none;
  }
}

.filter-datepicker-container {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      .filter-datepicker {
        width: 100%;
      }
    }
  }
}
