.pagination-component {
  background: white;
  padding: 15px !important;
  box-shadow: 0 3px 25px rgba(19, 49, 109, 0.08);
  border-radius: 5px;
  display: flex !important;
  align-items: center;
  height: 46px;
  border: 1px solid #ced3e0;
  li,
  li a {
    height: 22px !important;
    width: 22px !important;
    border: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #adb6cc;
  }

  li a {
    border-radius: 50%;
    &:not(.ant-pagination-item-active):hover {
      color: white;
      background: #adb6cc;
    }
  }

  .ant-pagination-item-active a {
    color: white !important;
    background: #13316d !important;
  }

  .ant-pagination-item-link {
    border: none !important;
    color: #1a263a;
  }
}
