.button-merge-clients {
  background-color: #ffffff !important;
  border: 1px solid #dfe6f1 !important;
  max-height: 36px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px;
  color: #14316d !important;
  padding: 0px 20px !important;

  @media screen and (max-width: 678px) {
    font-size: 14px;
    line-height: 16px;
    color: rgba(19, 49, 109, 0.4) !important;
    padding: 0px 15px;
  }
}

.active-button-merge-clients {
  background: #14316d !important;
  max-height: 36px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px;
  color: #ffffff !important;
  padding: 0px 20px !important;

  @media screen and (max-width: 678px) {
    font-size: 14px;
    line-height: 16px !important;
    padding: 0px 15px !important;
  }
}

.mobile-date-picker {
  @media screen and (min-width: 345px) {
    width: 45.83333333%;
    padding-right: 0 !important;
    padding-left: 0 !important;

    .ant-col-24 {
      padding-left: 2px !important;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
  }
}

.delete-account-success-message {
  background: #eefcf4;
  border: 1px solid #2fd574;
  padding: 17px 0 9px 50px;
  min-width: max-content;
  box-shadow: none;
  border-radius: 10px;
}

.account-linked-message {
  max-width: max-content;
  padding: 11px 24px;

  div {
    display: flex;
    max-height: 22px;
    .ant-notification-notice-message {
      margin: 0;
    }
  }

  .ant-notification-notice-close {
    top: 11px;
  }
}

@font-family: 'Work Sans', sans-serif;@border-radius-base: 20px;@primary-color: #14316D;@info-color: #6ce1f7;@minor-color: #ADB6CC;@link-color: #4395D4;@link-hover-decoration: underline;@label-color: #14316D;@normal-color: #13316D;@font-size-base: 16px;@font-size-lg: 18px;@font-size-xlg: 28px;@font-size-sm: 14px;@border-color-base: #ADB6CC;@form-item-margin-bottom: 19px;@disabled-bg: #F8F9FC;@layout-body-background: #F8F9FC;@avatar-color: #14316D;@avatar-bg: #E9ECF3;@box-border-decoration: 1px solid #e9e9e9;@btn-font-weight: 400;@btn-height-base: 42px;@btn-height-lg: 50px;@btn-height-sm: 34px;@btn-default-color: white;@btn-default-bg: #ADB6CC;@btn-default-border: #98a1b5;@input-height-base: 42px;@input-height-lg: 50px;@input-height-sm: 34px;@input-hover-border-color: #13316D;@table-header-bg: white;@table-footer-bg: white;@table-header-color: #14316D;@table-row-hover-bg: #F8F9FC;@z-index-mobile-menu: 1;@z-index-mobile-menu-header: 2;@z-index-mobile-sidebar: 1;@time-cell-min-width: 120;@time-cell-week-min-width: 40;@time-cell-height: 100;@disabled-time-bg: repeating-linear-gradient(-55deg, #eff2f8, #eff2f8 10px, #f8f9fc 10px, #f8f9fc 20px);@calendar-user-header-height: 50;@prefix-time-height: 40;@time-column-width: 80;@time-column-width-mobile: 40;@calendar-top-offset: 161;@calendar-mobile-top-offset: 110;@calendar-time-cell-width: 60;@calendar-time-cell-mobile-width: 45;