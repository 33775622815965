.ant-form-item-label {
  overflow: visible;
}

.wrapper-checkbox {
  padding: 40px 42px !important;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  margin-bottom: 30px;

  & + .ant-spin-nested-loading {
    div {
      .ant-spin {
        .ant-spin-dot {
          display: none;
        }
      }
    }
  }

  div {
    div {
      div {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        button.ant-switch {
          margin: 0;
        }

        button.ant-switch.ant-switch-checked {
          background: #13316d !important;
        }
      }

      div + div {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #13316d;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 14px 15px 24px !important;
    box-shadow: none;
    margin: 0;

    div {
      div {
        div {
          font-size: 16px;
          line-height: 19px;
        }

        div + div {
          margin-top: 12px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.checkboxGroup {
  div > label {
    margin: 0 !important;

    span.ant-checkbox-inner {
      background: #ffffff;
      border: 2px solid #13316d;
      border-radius: 3px;
      width: 24px;
      height: 24px;

      &:after {
        left: 17%;
        top: 48%;
        width: 10px;
        height: 15px;
      }
    }

    .ant-checkbox + span {
      padding: 0;
      margin-left: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #13316d;
    }
  }

  @media screen and (max-width: 768px) {
    div > label {
      .ant-checkbox + span {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
}

@font-family: 'Work Sans', sans-serif;@border-radius-base: 20px;@primary-color: #14316D;@info-color: #6ce1f7;@minor-color: #ADB6CC;@link-color: #4395D4;@link-hover-decoration: underline;@label-color: #14316D;@normal-color: #13316D;@font-size-base: 16px;@font-size-lg: 18px;@font-size-xlg: 28px;@font-size-sm: 14px;@border-color-base: #ADB6CC;@form-item-margin-bottom: 19px;@disabled-bg: #F8F9FC;@layout-body-background: #F8F9FC;@avatar-color: #14316D;@avatar-bg: #E9ECF3;@box-border-decoration: 1px solid #e9e9e9;@btn-font-weight: 400;@btn-height-base: 42px;@btn-height-lg: 50px;@btn-height-sm: 34px;@btn-default-color: white;@btn-default-bg: #ADB6CC;@btn-default-border: #98a1b5;@input-height-base: 42px;@input-height-lg: 50px;@input-height-sm: 34px;@input-hover-border-color: #13316D;@table-header-bg: white;@table-footer-bg: white;@table-header-color: #14316D;@table-row-hover-bg: #F8F9FC;@z-index-mobile-menu: 1;@z-index-mobile-menu-header: 2;@z-index-mobile-sidebar: 1;@time-cell-min-width: 120;@time-cell-week-min-width: 40;@time-cell-height: 100;@disabled-time-bg: repeating-linear-gradient(-55deg, #eff2f8, #eff2f8 10px, #f8f9fc 10px, #f8f9fc 20px);@calendar-user-header-height: 50;@prefix-time-height: 40;@time-column-width: 80;@time-column-width-mobile: 40;@calendar-top-offset: 161;@calendar-mobile-top-offset: 110;@calendar-time-cell-width: 60;@calendar-time-cell-mobile-width: 45;