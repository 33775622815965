.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-resp-center {

  @media (max-width: 960px){
    &{
      white-space: normal !important;
      display: block;
      width: 100%;
      text-align: center;
      word-break: break-word;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.color-red {
  color: #ff5e5e !important;
}

.color-green {
  color: #369649 !important;
}

.color-primary {
  color: @primary-color !important;
}

.color-link {
  color: @link-color !important;
}

.bg-blue {
  background: cornflowerblue;
}

.hidden {
  opacity: 0 !important;
}

.animate-opacity {
  opacity: 1;
  transition: opacity 0.3s;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.gap-x-5 {
  column-gap: 5px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-2 {
  padding: 16px;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-start{
  justify-content: start;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-1 {
  margin-top: 10px !important;
}

.br-default {
  border-radius: 20px !important;
}

.br-top-default {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.br-bottom-default {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mh-1 {
  min-height: 1px !important;
}

.z-1 {
  zoom: 1.1 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px !important;
}

.mr-sm {
  margin-right: 5px;
}

.mr-4 {
  margin-right: 40px;
}

.mb-sm {
  margin-bottom: 5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt--1 {
  margin-top: -10px;
}

.big-btn-padding {
  padding: 0 60px;
}

.d-none {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.br-1 {
  border-right: 1px solid #e9ecf3;
}

.bold {
  font-weight: 600 !important;
}

.position-static {
  position: static !important;
}

.br-small-select {
  .ant-select-selection {
    border-radius: 5px !important;
  }
}

.br-small {
  border-radius: 5px !important;
}

@media screen and (min-width: 768px) {
  .visible-md {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hidden-md {
    display: none;
  }
}

.position-relative {
  position: relative;
}

.capitalize {
  text-transform: capitalize;
}

.disable-number-controls {
  border-radius: 5px !important;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }
}

.table-row-font {
  color: #5c6986;
  font-size: 1rem;
  font-family: 'Work Sans', 'sans-serif'
}