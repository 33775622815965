.ant-alert {
  border: none;
  .ant-alert-message,
  & {
    color: #13316d;
  }

  .ant-alert-message {
    font-weight: 500;
  }
}
