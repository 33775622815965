textarea.ant-input {
  resize: none;
}

input.ant-input,
textarea.ant-input {
  border-radius: 5px;

  &:hover {
    border-color: @primary-color;
  }
  &:focus {
    border-color: @info-color;
    box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);

    &.ant-calendar-picker-input {
      border-color: @info-color !important;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @normal-color;
  border-color: @normal-color;
}

span.ant-checkbox-inner {
  background: #adb6cc;
  border-color: #adb6cc;
}

.ant-checkbox-checked::after {
  border-color: @normal-color !important;
}

span.ant-input-group-addon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;
}

button.ant-switch {
  background: #e9ecf3;
  &.ant-switch-checked {
    background: @normal-color;
  }
}

.search-input  {
  background: #e9ecf3 !important;
  border-width: 0 !important;
}
.password-no-suffix {
  .ant-input-suffix {
    display: none !important;
  }
}

.search-input-reports {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ced3e0;

  @media screen and (max-width: 600px) {
    input::placeholder {
      display: none !important;
    }
  }
}
