.status-badge {
  span {
    &:first-of-type {
      height: 12px !important;
      width: 12px !important;
    }
    &:after {
      display: none !important;
    }
  }

  &.new-appointment .ant-badge-status-dot {
    background-color: rgb(165, 223, 248) !important;
  }
  &.confirmed-appointment .ant-badge-status-dot {
    background-color: rgb(108, 213, 203) !important;
  }
  &.completed-appointment .ant-badge-status-dot {
    background-color: rgb(172, 178, 192) !important;
  }
  &.no-show-appointment .ant-badge-status-dot {
    background-color: rgb(255, 156, 187) !important;
  }
}
