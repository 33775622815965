

:root {
  --antd-wave-shadow-color: @normal-color;
}

div.ant-form-item-label {
  line-height: 19px;
}

#app .ant-layout {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

div.ant-notification {
  transform: translate(50%, 0);
  margin-right: 50%;
}

div.ant-form-item-control {
  line-height: 30px;

  div.ant-form-explain {
    min-height: 20px;
  }

  @media screen and (max-width: 768px) {
    div.ant-form-explain {
      min-height: 14px;
    }
  }
}

.ant-avatar {
  cursor: pointer;
  user-select: none;
}

.ant-switch {
  max-width: 30px !important;
}

button.ant-switch.ant-switch-checked.info-checkbox {
  background: @info-color;
}

div.resource-select-input {
  min-width: 200px;
}
