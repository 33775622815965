header.ant-layout-header.ant-layout-header {
  background: @layout-body-background;
  margin-bottom: 20px;
  padding: 0 30px;
  z-index: 120;

  @media screen and (max-width: 600px) {
    padding: 0;
    margin: 0;
    height: unset;
    line-height: 50px;
  }

  &.is-mobile {
    padding: 0;
    margin: 0;
    height: unset;
    line-height: 50px;
  }
}

@media screen and (min-width: 600px) {
  .sidebar-mobile:not(.is-mobile) {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .app-sidebar {
    display: none !important;
  }
}

main.ant-layout-content {
  position: relative;
  padding: 0 30px 30px 30px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    padding: 10px 10px 70px 10px;
    &.mobile-layout-content-white {
      background: white;
    }
  }

  &.is-mobile {
    margin-top: 50px;
    padding: 10px 10px 70px 10px;
    &.is-calendar-page {
      padding: 0;
    }
    &.mobile-layout-content-white {
      background: white;
    }
  }
}

.campaigns-page main.ant-layout-content {
  position: relative;
  padding: 0 30px 30px 30px;
  .ant-layout {
    min-height: 100vh !important;
  }

  @media screen and (max-width: 600px) {
    margin-top: 0px;
    padding: 10px 10px 0px 10px;
    &.mobile-layout-content-white {
      background: white;
    }
  }

  &.is-mobile {
    margin-top: 0px;
    padding: 10px 10px 0px 10px;
    &.is-calendar-page {
      padding: 10px 0 10px 0;
    }
    &.mobile-layout-content-white {
      background: white;
    }
  }
}

.ant-layout {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

.ant-layout-sider {
  box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16);
  color: @minor-color;
}

.ant-layout-sider-children {
  position: fixed;
}

.sidebar-mobile {
  z-index: 3;
}
.sidebar-mobile a {
  div {
    color: @minor-color;
  }
  &.active {
    div {
      color: white;
      background: @primary-color;
      path {
        fill: white;
      }
    }
  }
  &:hover {
    div {
      color: @minor-color;
      background: #e9ecf3;
      path {
        fill: @minor-color;
      }
    }
  }
}

.app-sidebar {
  z-index: 2;
  border-right: 1px solid #e8e8e8;
  flex: 0 0 112px !important;
  max-width: 112px !important;
  width: 112px !important;
  .ant-menu {
    border: none;
    margin-left: -1px;
    &:not(.ant-menu-horizontal) li.ant-menu-item-selected {
      background: inherit;
      &:after {
        display: none !important;
      }
    }
  }

  .ant-menu-item {
    a {
      color: @minor-color;
      text-decoration: none !important;
      margin-left: -15px;
      padding-left: 15px;
      border-radius: 20px;
      height: 36px !important;
      min-width: 100%;
      width: 100%;
      margin-top: 3px;
      font-weight: 500;
      &.active {
        color: #fff;
        background: @primary-color;
        path {
          fill: #fff;
        }
      }
      &:hover {
        color: @minor-color;
        background: #14316d;
        path {
          fill: @minor-color;
        }
      }
    }
  }

  [data-sidebar-support-icon] {
    display: flex;
    flex-direction: column;
    color: @minor-color;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    height: 72px;
    font-weight: 500;
    span {
      max-width: none !important;
      opacity: 1 !important;
      width: 90px;
      white-space: break-spaces;
      padding: 0 !important;
      text-align: center;
    }
    i {
      line-height: 30px !important;
    }
    &:hover {
      color: #fff;
      background: #14316d;
      path {
        fill: @minor-color;
      }
    }
  }

  [data-sidebar-update-icon] {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    background: #136ef6;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    height: 72px;
    font-weight: 500;
    span {
      max-width: none !important;
      opacity: 1 !important;
      width: 90px;
      white-space: break-spaces;
      padding: 0 !important;
      text-align: center;
    }
    i {
      line-height: 30px !important;
    }
    &:hover {
      color: #fff;
      background: #14316d;
      path {
        fill: @minor-color;
      }
    }
  }

  &.ant-layout-sider-collapsed,
  &.sidebar-collapsed {
    .ant-menu-item.ant-menu-item {
      a {
        padding-left: 8px;
      }
    }
  }

  .app-sidebar-trigger {
    padding: 20px;
    cursor: pointer;
    text-align: center;
    svg {
      transform: rotate(180deg);
      height: 21px;
      width: 21px;
      transition: transform 0.3s;
      path:first-of-type {
        fill: #fff;
      }
    }

    &:hover {
      i {
        border-radius: 50%;
        background: @minor-color;
        svg {
          path:first-of-type {
            fill: #fff;
          }
          path:last-of-type {
            fill: transparent;
          }
        }
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .app-sidebar-trigger {
      padding: 20px 0px 20px 6px;
      text-align: center;
      svg {
        transform: rotate(0deg);
      }
    }
  }
}

.app-sidebar--no-shadow {
  box-shadow: none;
}
