

table {
  background: white;
  color: @primary-color !important;
  font-size: 16px;
}

tbody.ant-table-tbody {
  background: white;
  color: #5c6986;
}

.drag-cell {
  cursor: grab;
  width: 45px;
}

.ant-table {
  overflow: auto;
}

div.app_clients-list--mobile {
  margin-top: 60px;
}

.staff-members-table__status-badge {
  .ant-badge-status-dot {
    position: absolute;
    margin-top: 6px;
  }
}

.reports-table {
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 5px;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 5px;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 5px;
  }
  .ant-table-footer {
    display: none;
  }
}
