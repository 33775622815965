

li.ant-dropdown-menu-item {
  padding: 8px 20px;
  a {
    text-decoration: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    color: @primary-color;
    background: #f8f9fc !important;
    a {
      color: @primary-color !important;
      background: #f8f9fc !important;
    }
  }
}

li.ant-menu-item {
  &:active {
    background: #a3a8ad24;
  }
  a {
    text-decoration: none;
  }
}

li.ant-menu-submenu-title:active {
  background: #a3a8ad24;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #14316d;
  color: #fff;
}

.app-common-dropdown {
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(19, 49, 109, 0.16) !important;
  .ant-menu-item {
    &:hover,
    &:active,
    &:focus {
      color: @primary-color;
      background: #f8f9fc !important;
      a {
        color: @primary-color !important;
      }
    }
  }
}

.app-common-dropdown-with-buttons {
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(19, 49, 109, 0.16) !important;
  max-width: 190px;
  border-radius: 5px;
  padding: 0 3px;
  button {
    background-color: white;
    width: 100%;
    border-radius: 3px;
    height: 36px;
    text-align: start;
    &:hover,
    &:active,
    &:focus {
      color: @primary-color;
      background: #f8f9fc !important;
    }
    div {
      margin-bottom: 0;
    }
  }
}

.phone-input--dropdown {
  min-width: 497px;
  @media screen and (max-width: 768px) {
    min-width: 328px;
  }
}

.rec-specific-date-dropdown {
  display: none;
}

.calendar-filter__dropdown {
  min-width: 200px;
  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item {
    padding-left: 35px;
  }
}

.ant-input-search {
  width: 95%;
}

.ant-menu-inline-collapsed {
  width: 112px !important;
  .ant-menu-item {
    height: 72px;
    width: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 12px;
    line-height: 12px;
    a {
      display: flex;
      flex-direction: column;
      min-height: 72px;
      padding: 0 !important;
      margin: auto;
      justify-content: center;
      border-radius: 0;
      span {
        max-width: none !important;
        opacity: 1 !important;
        width: 90px;
        white-space: break-spaces;
        text-align: center;
      }
      i {
        line-height: 30px !important;
      }
    }
  }
}
