

.modal-mobile-menu {
  max-width: 350px;
}

.ant-modal-close {
  margin-top: 20px;
  margin-right: 20px;
  height: 40px;
  width: 45px;
}

.ant-modal-content {
  padding: 20px 40px 40px 40px;
  width: 570px;
  max-width: 100%;
}

.ant-modal-body {
  @media (max-width: 600px) {
    padding: 20px !important;
  }
}

.client-content {
  .ant-modal-content {
    padding: 20px 0 0;
    min-height: 0;
    width: 650px;
    max-width: none;
    border-radius: 20px;
  }
  .ant-modal-body {
    padding-top: 24px;
    padding-bottom: 0;
  }
  .ant-modal {
    width: 650px !important;
    top: 100px;
    margin: 0 auto;
  }
}

.service-content {
  .ant-modal-content {
    padding: 20px 0 0;
    min-height: 0;
    width: 650px;
    max-width: none;
    border-radius: 20px;
  }
  .ant-modal-body {
    padding-top: 24px;
    padding-bottom: 0;
  }
  .ant-modal {
    width: 650px !important;
    top: 53px;
    margin: 0 auto;
  }
}

.upgrade-modal-content {
  .ant-modal-content {
    padding: 10px 30px 5px 30px;
    width: 540px !important;
    max-width: 100%;
  }
}

.payment-modal-content {
  .ant-modal {
    .ant-modal-title {
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0;
      text-align: center;
    }
    .ant-modal-content {
      padding: 30px 30px 20px 30px;
      width: 600px !important;
      max-width: 100%;
      border-radius: 5px;
    }
    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.ant-modal-header {
  text-align: center;
  padding: 20px 24px;
}

.ant-modal-title {
  font-size: 28px !important;
  color: #61749d !important;
}

.ant-modal-wide {
  width: 810px !important;
  .ant-modal-content {
    width: 810px;
  }
}

.ant-modal-middle {
  .ant-modal-content {
    width: 600px;
  }
}

.client-select-modal {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.age-modal-content .ant-modal-content {
  padding: 0;
  margin: 205px auto 0 auto;
  .ant-modal-header {
    border: none;
  }
  .ant-modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.payment-modal-content .ant-modal-content {
  margin: 135px auto 0 auto;
}

.reset-campagin-modal .ant-modal-content {
  width: 615px;
  max-width: none;
}

.ant-modal-title {
  font-size: 28px !important;
  color: #13316d !important;
  margin-top: 0;
}

.pricing-modal-content {
  .ant-modal {
    width: 615px !important;
    .ant-modal-header {
      border: none;
      padding: 0;
    }
    .ant-modal-title {
      line-height: 33px;
      padding-bottom: 35px;
    }
    .ant-modal-content {
      padding: 27px 61px 50px 24px;
      margin: 0 auto;
      width: 615px !important;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.import-clients-modal-content {
  .ant-modal {
    width: 540px !important;

    .ant-modal-content {
      padding: 20px;
    }

    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.preview-message-modal {
  .ant-modal-content {
    padding: 20px 40px 0;
  }
  .ant-modal {
    top: 60px;
    padding-bottom: 0;
  }
}

.campaign-activation-modal {
  .ant-modal-body {
    padding: 24px 8px;
  }
}

.create-category-Modal {
  .ant-modal {
    top: 330px;
    padding-bottom: 0;
  }
}

.repeat-appointment-modal {
  .ant-modal {
    background: #ffffff;
    box-shadow: 0 12px 30px rgba(38, 60, 122, 0.25);
    border-radius: 5px !important;

    @media (min-width: 1021px) {
      width: 303px !important;
    }

    .ant-modal-close svg {
      height: 36px !important;
      width: 36px !important;

      @media (min-width: 1021px) {
        display: none;
      }
    }
    .ant-modal-header {
      @media (min-width: 1021px) {
        display: none;
      }
    }
    .ant-modal-content {
      box-shadow: none;
      padding: 0;
      width: inherit;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.edit-appointment-modal {
  .ant-modal {
    background: #ffffff;
    box-shadow: 0 12px 30px rgba(38, 60, 122, 0.25);
    border-radius: 5px;
    width: 35.625rem !important;
    top: 17%;
    .ant-modal-title {
      margin-top: 12px;
    }
    .ant-modal-content {
      box-shadow: none;
      padding: 0;
      width: inherit;
      .ant-modal-body {
        padding: 0;
      }
    }
    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.upgrade-to-premium-modal {
  .ant-modal {
    width: 599px !important;
    .ant-modal-header {
      border: none;
      padding: 0 0 16px;
      .ant-modal-title {
        font-weight: 600;
        line-height: normal;
      }
    }
    .ant-modal-content {
      padding: 77px 58px;
      border-radius: 5px;
      width: inherit;
      .ant-modal-body {
        padding: 0;
        div {
          font-size: 16px;
        }
        button {
          max-width: max-content;
          border: none;
          display: block;
          margin: 0 auto;
        }
        .ant-btn-primary {
          background: #349447;
          border-radius: 5px;
        }
        .ant-btn-ghost {
          box-shadow: none;
        }
      }
    }

    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.card-tooltip-modal {
  .ant-modal {
    .ant-modal-content {
      border-radius: 5px;
    }
  }
}

.premium-feature-modal {
  .ant-modal {
    width: 342px !important;

    .ant-modal-content {
      padding: 20px;
    }

    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.select-language-modal {
  overflow: hidden;
  .ant-modal {
    padding: 0;
    .ant-modal-header {
      border: none;
      padding: 0;
    }
    .ant-modal-title {
      line-height: 33px;
      margin-bottom: 24px;
      margin-top: 8px;
    }
    .ant-modal-content {
      height: 570px;
      padding: 24px;
      width: 450px;
      margin: 0 auto;
      .ant-modal-body {
        text-align: center;
        padding: 0;
      }
    }

    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.delete-rm-modal {
  .ant-modal {
    .ant-modal-header {
      padding: 0 0 20px;
    }
    .ant-modal-content {
      padding: 30px 46px 40px;
      width: 479px;
      margin: 0 auto;
      text-align: center;
      .ant-modal-body {
        padding: 20px 0 0;
      }
    }
  }
}

.send-test-message-modal {
  .ant-modal {
    .ant-modal-content {
      padding: 70px 70px 60px;
      max-width: 505px;
      margin: 0 auto;
      .ant-modal-body {
        padding: 0;
        div.ant-form-item-control-wrapper {
          margin-bottom: 30px;
          input {
            color: #213e76;
          }
        }
      }
    }
  }
}

.pricing-feature-modal-content {
  .ant-modal {
    width: 700px !important;
    .ant-modal-header {
      border: none;
      padding: 0 0 29px;
    }
    .ant-modal-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 33px;
    }
    .ant-modal-content {
      width: inherit;
      padding: 60px 114px 48px;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.duplicated-clients-modal {
  .ant-modal {
    .ant-modal-header {
      border: none;
      padding: 0;
    }
    .ant-modal-title {
      line-height: 33px;
      margin-bottom: 40px;
    }
    .ant-modal-content {
      padding: 27px 0 0;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.confirm-duplicates-clients-modal {
  .ant-modal {
    min-width: 550px;
    .ant-modal-header {
      padding: 0;
    }
    .ant-modal-title {
      margin-bottom: 26px;
    }
    .ant-modal-content {
      padding: 27px 40px 40px;
      .ant-modal-body {
        margin: 32px 7px 0;
        line-height: 22px;
        color: #13316d;
        padding: 0;
      }
    }
  }
}

.cancel-appointment-modal {
  .ant-modal {
    min-width: 541px;
    .ant-modal-content {
      box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
      padding: 62px 115px 36px;
      border-radius: 10px;
      .ant-modal-close {
        display: none;
      }
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.success-cancel-appointment-modal {
  .ant-modal {
    min-width: 541px;
    .ant-modal-content {
      box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
      padding: 72px 116px;
      border-radius: 10px;
      .ant-modal-close {
        display: none;
      }
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.help-modal {
  .ant-modal {
    min-width: 570px;
    .ant-modal-header {
      border: 0;
      padding: 0;
    }
    .ant-modal-title {
      font-weight: 600;
      margin-bottom: 30px;
      line-height: 33px;
    }
    .ant-modal-content {
      padding: 24px 76px 42px 72px;
      .ant-modal-body {
        padding: 0;
      }
    }
    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.online-booking-analytics-modal {
  .ant-modal {
    min-width: 1230px;
    .ant-modal-header {
      border: 0;
      padding: 0;
    }
    .ant-modal-title {
      font-weight: 600;
      margin-bottom: 68px;
      line-height: 33px;
    }
    .ant-modal-content {
      width: 100%;
      padding: 83px 133px 72px 104px;
    }
  }
}

.delete-account-modal {
  .ant-modal {
    min-width: 591px;
    .ant-modal-content {
      width: 591px;
      padding: 96px 84px;
      .ant-modal-body {
        padding: 0;
      }
    }
    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

.cancel-subscription-error-modal {
  .ant-modal {
    width: 774px !important;
    .ant-modal-content {
      width: inherit;
      padding: 64px 118px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.latest-payment-failed-modal {
  .ant-modal {
    width: 800px !important;
    .ant-modal-content {
      width: inherit;
      padding: 60px 107px 64px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.extra-costs-for-new-member-modal {
  .ant-modal {
    width: 700px !important;
    .ant-modal-header {
      border: none;
      padding: 0 0 24px;
    }
    .ant-modal-title {
      font-size: 28px;
      font-weight: 600;
      line-height: 33px;
    }
    .ant-modal-content {
      width: inherit;
      padding: 30px 100px;
      text-align: center;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.premium-upgrade-required-modal {
  .ant-modal {
    width: 700px !important;
    .ant-modal-header {
      border: none;
      padding: 0 0 29px;
    }
    .ant-modal-title {
      font-weight: 600;
      line-height: 33px;
    }
    .ant-modal-content {
      width: inherit;
      padding: 60px 140px 64px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.delete-payment-method-modal {
  .ant-modal {
    width: 700px !important;
    .ant-modal-header {
      border: none;
      padding: 0 0 29px;
    }
    .ant-modal-title {
      font-weight: 600;
      line-height: 33px;
    }
    .ant-modal-content {
      width: inherit;
      padding: 60px 140px 64px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.payment-error-modal {
  .ant-modal {
    min-width: 600px;
    .ant-modal-header {
      border: 0;
      padding: 0;
    }
    .ant-modal-title {
      font-weight: 600;
      line-height: 33px;
      max-width: 322px;
      margin: 0 auto 17px;
      font-size: 28px !important;
      div {
        gap: 10px;
        svg {
          width: 36px;
          height: 36px;
        }
        div {
          font-size: inherit;
        }
      }
    }
    .ant-modal-content {
      min-width: inherit;
      text-align: center;
      padding: 70px 58px;
      border-radius: 5px;
      .ant-modal-body {
        padding: 0;
        button {
          padding: 11px 40px;
          line-height: 19px;
          font-size: 16px;
        }
      }
    }
    .ant-modal-close-x {
      svg {
        path {
          fill: #adb6cc;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .extra-costs-for-new-member-modal {
    .ant-modal {
      width: calc(100vw - 16px) !important;
      .ant-modal-content {
        width: inherit;
        padding: 76px 30px 48px;
      }
    }
  }
}

@media screen and (max-width: 678px) {
  .ant-modal-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    margin: 0;
    max-width: 100%;
    background: #fff;
    padding-bottom: 30px;
  }
  .ant-modal-content {
    padding: 0 !important;
  }
  .ant-modal-close {
    margin: 15px !important;
    svg {
      height: 40px !important;
      width: 40px !important;
    }
    .ant-modal-content {
      width: inherit;
      padding: 60px 114px 48px;
    }

    .ant-modal {
      max-width: 100%;
      top: 0;
      margin: 0;
      position: fixed;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-title {
    font-size: 18px !important;
  }

  .client-content {
    .ant-modal-content {
      padding: 0;
      min-height: 100vh;
      border-radius: 0;
    }
    .ant-modal {
      max-width: 100%;
      top: 0;
      margin: 0;
      position: fixed;
    }
  }
  .age-modal-content .payment-modal-content .ant-modal-content {
    margin: 140px auto 0 auto;
  }
  .payment-modal-content .ant-modal-content {
    margin: 105px auto 0 auto;
  }

  .reset-campagin-modal .ant-modal-content {
    width: auto;
    max-width: 100%;
    .ant-modal-title {
      font-size: 28px !important;
      margin-top: 40px;
    }
  }

  .pricing-modal-content {
    .ant-modal {
      .ant-modal-title {
        font-size: 28px !important;
        padding-bottom: 14px;
      }
      .ant-modal-content {
        padding: 52px 47px 40px 16px !important;
      }
    }
  }

  .preview-message-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: visible;
    outline: 0;
    .ant-modal {
      max-width: 100vw !important;
      top: 0 !important;
      margin: 0 !important;
    }
    .ant-modal-content {
      height: 100vh !important;
      border-radius: 0 !important;
    }
  }

  .service-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: visible;
    outline: 0;
    .ant-modal {
      max-width: 100%;
      top: 0;
      .ant-modal-content {
        width: 100%;
        border-radius: 0;
        height: 100vh;
        .ant-modal-body {
          padding: 20px 0 0 0;
        }
      }
    }
  }

  .payment-modal-content {
    .ant-modal {
      .ant-modal-content {
        padding: 60px 15px 40px !important;
      }
    }
  }

  .select-language-modal {
    overflow: auto;
    .ant-modal {
      .ant-modal-title {
        font-size: 28px !important;
        font-weight: 600;
        margin-top: 42px;
      }
      .ant-modal-content {
        padding: 16px !important;
        height: 100%;
      }
      .ant-modal-close-x {
        svg {
          path {
            fill: #13316d;
          }
        }
      }
    }
  }

  .delete-rm-modal {
    .ant-modal {
      .ant-modal-header {
        padding-bottom: 14px;
      }
      .ant-modal-title {
        font-size: 27px !important;
        line-height: 33px;
      }
      .ant-modal-content {
        padding: 67px 38px 41px !important;
      }
    }
  }

  .pricing-feature-modal-content {
    .ant-modal {
      .ant-modal-title {
        font-size: 28px !important;
      }
      .ant-modal-header {
        padding-bottom: 16px;
        border-bottom: 1px solid #e9ecf3;
        margin-bottom: 17px;
      }
      .ant-modal-content {
        padding: 58px 16px 54px !important;
      }
    }
  }

  .duplicated-clients-modal {
    .ant-modal {
      .ant-modal-title {
        font-weight: 500;
        font-size: 20px !important;
        line-height: 23px;
        margin-bottom: 31px;
      }
      .ant-modal-content {
        padding: 39px 0 0 !important;
      }
    }
  }

  .send-test-message-modal {
    .ant-modal {
      .ant-modal-content {
        padding: 76px 16px 51px !important;
        max-width: 100%;
      }
    }
  }

  .cancel-subscription-error-modal {
    .ant-modal {
      .ant-modal-content {
        padding: 51px 31px 40px !important;
      }
      .ant-modal-body {
        padding: 0;
      }
    }
  }

  .latest-payment-failed-modal {
    .ant-modal {
      .ant-modal-content {
        padding: 51px 29px 40px !important;
      }
      .ant-modal-body {
        padding: 0;
      }
    }
  }

  .extra-costs-for-new-member-modal {
    .ant-modal {
      .ant-modal-title {
        font-size: 28px !important;
      }
      .ant-modal-content {
        padding: 76px 20px 48px !important;
      }
    }
  }

  .premium-upgrade-required-modal {
    .ant-modal {
      .ant-modal-header {
        padding-bottom: 24px;
      }
      .ant-modal-title {
        font-size: 28px !important;
      }
      .ant-modal-content {
        padding: 76px 30px 48px !important;
      }
    }
  }

  .confirm-duplicates-clients-modal {
    .ant-modal {
      min-width: 343px;
      .ant-modal-title {
        font-size: 20px !important;
        margin-bottom: 22px;
        line-height: 23px;
      }
      .ant-modal-content {
        padding: 22px 16px 40px !important;
        .ant-modal-body {
          margin: 13px 0 0;
        }
      }
    }
  }

  .cancel-appointment-modal {
    .ant-modal {
      min-width: 343px;
      .ant-modal-content {
        padding: 66px 35px 63px !important;
      }
    }
  }

  .success-cancel-appointment-modal {
    .ant-modal {
      min-width: 343px;
      .ant-modal-content {
        padding: 81px 20px 72px !important;
      }
    }
  }

  .delete-account-modal {
    .ant-modal {
      min-width: auto;
      .ant-modal-content {
        padding: 36px 16px !important;
      }
    }
  }

  .upgrade-to-premium-modal {
    .ant-modal-header {
      padding-bottom: 8px;
      .ant-modal-title {
        font-size: 24px !important;
        font-weight: 600;
      }
      .ant-modal-content {
        padding: 44px 16px !important;
        .ant-modal-body {
          div {
            font-size: 14px !important;
          }
          .ant-btn-primary {
            min-width: 100%;
          }
        }
      }
    }
  }

  .delete-payment-method-modal {
    .ant-modal {
      min-width: auto;
      .ant-modal-header {
        padding-bottom: 24px;
      }
      .ant-modal-title {
        font-size: 28px !important;
      }
      .ant-modal-content {
        padding: 76px 30px 64px !important;
      }
    }
  }

  .payment-error-modal {
    .ant-modal {
      min-width: auto;
      .ant-modal-content {
        padding: 67px 44px 40px !important;
        .ant-modal-body {
          button {
            padding: 11px 67px;
          }
        }
      }
    }
  }
}
